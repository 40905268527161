import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import { TypeAnimation } from 'react-type-animation';
import axios from 'axios';
import Transition from '../transition';

const Blog = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(
          'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@nathannl'
        );
        setPosts(response.data.items.slice(0, 5)); // Get the 5 most recent posts
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    };

    fetchPosts();
  }, []);

  // Function to strip HTML tags
  const stripHtml = (html) => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  return (
    <div className="relative bg-light dark:bg-dark w-full min-h-screen snap-y snap-mandatory overflow-y-scroll">
      <Navbar />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="h-screen">
          <div className="flex flex-col justify-center h-full p-10 mt-20">
            <TypeAnimation 
              sequence={[
                "i have a blog!"
              ]}
              wrapper="span"
              className="font-bold text-textheading inline-block text-left w-full dark:text-white"
              style={{
                fontFamily: "'Roboto Mono', monospace",
              }}
            />
            <h1 className="text-textsm dark:text-white" style={{fontFamily: "'Roboto Mono', monospace"}}>
              is it cringe? perhaps. healthy though. 
            </h1>
          </div>
        </div>
        <div className="h-screen mt-20 overflow-y-auto">
          <div className="flex flex-col justify-start h-full gap-10 p-10 mt-20 mb-20">
            {posts.map((post, index) => (
              <div key={index} className="flex flex-col gap-2">
                <p className="text-text2 font-bold dark:text-white" style={{fontFamily: "'Roboto Mono', monospace"}}>
                  {post.title}
                </p>
                <p className="text-sm dark:text-white" style={{fontFamily: "'Roboto Mono', monospace"}}>
                  {stripHtml(post.description).substring(0, 200)}...
                </p>
                <p 
                  className="text-blue-500 hover:text-blue-700 dark:text-blue-300 dark:hover:text-blue-500 cursor-pointer"
                  style={{fontFamily: "'Roboto Mono', monospace"}}
                  onClick={() => window.open(post.link, '_blank', 'noopener,noreferrer')}
                >
                  Read more
                </p>
                <div className="border-b-2 border-black dark:border-white w-full mt-2"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Add spacing at the bottom for xs mode */}
      <div className="h-20 xs:h-40 md:h-0"></div>
    </div>
  );
};

export default Transition(Blog);
