import React from 'react';
import Navbar from './Navbar';
import { TypeAnimation } from 'react-type-animation';
import Soma from '../buttons/soma';
import Pending from '../buttons/pending';
import Snkrgram from '../buttons/snkrgram';
import Transition from '../transition';

const Projects = () => {
  return (
    <div className="relative bg-light dark:bg-dark w-full min-h-screen snap-y snap-mandatory overflow-y-scroll">
      <Navbar />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="h-screen">
          <div className="flex flex-col justify-center h-full p-10 mt-16">
            <TypeAnimation 
              sequence={[
                "stuff i've made:"
              ]}
              wrapper="span"
              className="font-bold text-textheading inline-block text-left w-full dark:text-white"
              style={{
                fontFamily: "'Roboto Mono', monospace",
              }}
            />
            <h1 className="text-textsm dark:text-white" style={{fontFamily: "'Roboto Mono', monospace"}}>
              self taught dev work. if you're not a cs major but like building anyways, <a href="https://discord.gg/aKY3qRKW" className="underline hover:text-blue-500 transition-colors duration-300">check out my discord!</a>
            </h1>
          </div>
        </div>
        <div className="h-screen">
          <div className="flex flex-col justify-center h-full gap-10 p-10 mt-16">
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-col gap-2">
                <h1 className="text-text2 font-bold dark:text-white" style={{fontFamily: "'Roboto Mono', monospace"}}>
                  pending... (active)
                </h1>
                <div className="flex flex-row">
                  <h1 className="text-sm dark:text-white max-w-[550px] pr-16" style={{fontFamily: "'Roboto Mono', monospace"}}>
                    a tool for you to keep track of their failures/rejections. send/forward an email to 
                    [username]@nowpending.com with a title, notes, and a reminder frequency, and receive reminders
                    via email, along with a frontend to view/edit all items. built with node.js, express, react, tailwind, mongoDB, + an openai api
                    call for fun :) 
                  </h1>
                  <Pending className="fixed mb-0"/>
                </div>
                <div className="border-b-2 border-black dark:border-white max-w-[780px] mt-2"></div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <h1 className="text-text2 font-bold dark:text-white" style={{fontFamily: "'Roboto Mono', monospace"}}>
                snkrgram (2020-2022)
              </h1>
              <div className="flex flex-row">
                <h1 className="text-sm dark:text-white max-w-[550px] pr-16" style={{fontFamily: "'Roboto Mono', monospace"}}>
                  a marketplace for luxury items catered towards bulk resellers (like myself at the time). based on a subscription 
                  model instead of per-sale fees, it encourages sellers to sell more items, giving buyers more options and lower 
                  costs. reached 500+ users, hosted 2 events with 1k+ attendees. shut down due to lack of time + luxury market
                  dying down. built by outsourcing :(
                </h1>
                <Snkrgram className="fixed mb-0"/>
              </div>
              <div className="border-b-2 border-black dark:border-white w-full mt-2"></div>
            </div>
          </div>
        </div>
      </div>
      {/* Add spacing at the bottom for xs mode */}
      <div className="h-20 xs:h-40 md:h-0"></div>
    </div>
  );
};

export default Transition(Projects);
