import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Homepage from './components/Homepage';
import Projects from './components/Projects';
import Community from './components/Community';
import Blog from './components/Blog';
import './App.css';
import { AnimatePresence } from 'framer-motion';

function App() {
  const location = useLocation();
  return (
    <>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route index element={<Homepage />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/community" element={<Community />} />
          <Route path="/blog" element={<Blog />} />
        </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;
