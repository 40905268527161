import React from 'react';


const Plugandplay = () => {
    const handleClick = () => {
        window.open('https://www.plugandplaytechcenter.com/', '_blank');
    };

    return (
        <button
            onClick={handleClick}
            className="w-12 h-12 bg-contain bg-no-repeat bg-center hover:scale-110 transition-opacity dark:invert"
            style={{ backgroundImage: "url('/arrow.svg')" }}
            aria-label="arrow"
        />
    );
};

export default Plugandplay;