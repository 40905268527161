import React from 'react';
import "../App.css";
import {useNavigate} from 'react-router-dom';
import {useState, useEffect} from 'react';
import { DarkModeSwitch } from 'react-toggle-dark-mode';

const Navbar = () => {
    const navigate = useNavigate();
    const [isDarkMode, setDarkMode] = useState(false);

    const toggleDarkMode = (checked) => {
        setDarkMode(checked);
        if (checked) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    };

    useEffect(() => {
        // Check for user's preference or system setting
        const isDark = localStorage.getItem('darkMode') === 'true' || 
                       (!('darkMode' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches);
        setDarkMode(isDark);
        toggleDarkMode(isDark);
    }, []);

    return (
        <nav className="fixed top-0 left-0 z-50 bg-light dark:bg-dark flex flex-col gap-3 sm:gap-5 w-full h-auto px-2 xs:px-4 sm:px-10 pt-2 sm:pt-6">
            <div className="flex flex-col xs:flex-row gap-3 xs:gap-4 sm:gap-6 py-2 sm:py-3 justify-between items-center">
                <div className="flex flex-wrap justify-center xs:justify-start gap-3 xs:gap-4 sm:gap-6">
                    {['home', 'projects', 'community', 'blog'].map((item) => (
                        <button
                            key={item}
                            className="transform text-light dark:text-dark transition ease-in-out duration-300 hover:underline text-sm xs:text-base sm:text-lg md:text-xl"
                            style={{ 
                                fontFamily: "'Roboto Mono', monospace",
                                background: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                            }}
                            onClick={() => navigate(item === 'home' ? '/' : `/${item}`)}
                        >
                            {item}
                        </button>
                    ))}
                </div>
                <div className="mt-3 xs:mt-0">
                    <DarkModeSwitch
                        checked={isDarkMode}
                        onChange={toggleDarkMode}
                        size={20}
                        moonColor={'#ffffff'}
                        sunColor={'#000000'}
                    />
                </div>
            </div>
            <div className="w-full border-t-2 sm:border-t-4 border-light dark:border-dark"></div>
        </nav>
    )
};

export default Navbar;
