import React, { useEffect, useState, useRef } from 'react';
import { TypeAnimation } from 'react-type-animation';
import Navbar from './Navbar';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the styles
import Transition from '../transition';

const Community = () => {
  const [showContent, setShowContent] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShowContent(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (showContent && contentRef.current) {
      const navbar = document.querySelector('.navbar');
      const navbarHeight = navbar ? navbar.offsetHeight : 0;
      window.scrollTo({
        top: contentRef.current.offsetTop - navbarHeight - 20,
        behavior: 'smooth'
      });
    }
  }, [showContent]);

  return (
    <div className="relative bg-light dark:bg-dark w-full min-h-screen snap-y snap-mandatory overflow-y-scroll">
      <Navbar className="sticky top-0 left-0 right-0 z-50 navbar" />
      <div className="h-screen flex items-center justify-center snap-start">
        <div className="p-10 mt-20 flex flex-col md:flex-row items-center w-full">
          <div className="flex flex-col gap-2 mt-6 w-full md:w-auto text-center md:text-left">
            <TypeAnimation 
              sequence={[
                "community first."
              ]}
              wrapper="span"
              className="font-bold text-textmain inline-block w-full dark:text-white"
              style={{
                fontFamily: "'Roboto Mono', monospace",
              }}
            />
            <p className="text-textsm dark:text-white" style={{fontFamily: "'Roboto Mono', monospace"}}>
              i &lt;3 getting people together. contact me if you want to collaborate.
            </p>
          </div>
          <div className="mt-8 md:mt-0 md:ml-32 md:pt-16 w-full md:w-1/2 max-w-[600px] rounded-lg overflow-hidden">
            <Carousel
              showArrows={true}
              infiniteLoop={true}
              showThumbs={false}
              showStatus={false}
              autoPlay={true}
              interval={3000}
            >
              <div>
                <img src="/eir1.png" alt="Community 1" className="rounded-lg" /> {/* Added rounded-lg to images */}
              </div>
              <div>
                <img src="/event1.png" alt="Community 2" className="rounded-lg" />
              </div>
              <div>
                <img src="/shmurda.png" alt="Community 3" className="rounded-lg" />
              </div>
              <div>
                <img src="/eir2.png" alt="Community 4" className="rounded-lg" />
              </div>
              <div>
                <img src="/event2.png" alt="Community 5" className="rounded-lg" />
              </div>
            </Carousel>
          </div>
        </div>
      </div>
      <div ref={contentRef} className={`transition-opacity duration-500 ${showContent ? 'opacity-100' : 'opacity-0'} pt-32`}>
        <div className="min-h-screen flex flex-col p-10 justify-start snap-start w-full">
          <div className="flex flex-col gap-2">
            <h1 className="text-textheading font-bold dark:text-white" 
                style={{fontFamily: "'Roboto Mono', monospace"}}>
              community stories:
            </h1>
            <div className="border-b-2 border-black dark:border-white w-full mt-2"></div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="flex flex-row h-auto w-full gap-2">
            <div className="border-l-2 border-black dark:border-white h-full mr-2"></div>
              <div className="flex flex-col gap-2">
                <h1 className="text-text2 font-bold dark:text-white mt-4" 
                  style={{fontFamily: "'Roboto Mono', monospace"}}>
                  eir: 
                </h1>
                <h1 className="text-textsm dark:text-white" 
                  style={{fontFamily: "'Roboto Mono', monospace"}}>
                    <strong>if you want to support people, they'll support you.</strong>
                    <br /><br />
                    i spent my first year and a half at nyu trying to fit into the entrepreneurial ecosystem. 
                    to be frank, it was kind of mediocre. resources existed but were hard to find, and student-led 
                    community for founders was almost non-existent. so, last semester, i decided to create my own. 
                    <br /><br />
                    here's what we've done so far: 
                    <br />
                    • hosted 3 events with ~100 attendees<br />
                    • sourced 6 top nyu founders to work alongside one another<br />
                    • established a vast resource network including investors, mentors, and partners<br />
                    • established a joint community with nyu'sentrepreneurship club<br />
                    • helped raise over $1m+ in 3 months, with a YC acceptance :)
                    <br /><br />
                    and here's what's next:<br />
                    • hosting speaker series with past nyu founders<br />
                    • a demo day for all nyu founders<br />
                    • more events, with more partners<br />
                    • and a brand new cohort of talented founders.
                  </h1>
              </div>
              <div className="border-l-2 border-black dark:border-white h-full ml-2"></div>
            </div>
            <div className="flex flex-row h-auto w-full gap-2">
              <div className="flex flex-col gap-2">
                <h1 className="text-text2 font-bold dark:text-white mt-4" 
                  style={{fontFamily: "'Roboto Mono', monospace"}}>
                  @juicy_kix:
                </h1>
                <h1 className="text-textsm dark:text-white" 
                  style={{fontFamily: "'Roboto Mono', monospace"}}>
                    <strong>never overlook your origins.</strong>
                    <br /><br />
                    i joined instagram in 8th grade when my parents first let me download the app. two months later, 
                    i picked up sneaker reselling as a hobby, and made my own account, @juicy_kix. this was my first
                    introduction to the "hustle", but i quickly learned that community-driven growth meant everything.
                    <br /><br />
                    as a 14 year old, i made my rounds in the community. i made discord servers, business partners, and 
                    most of all, friends. albeit with dudes a lot older than me. not weird i promise. 
                    <br /><br />
                    in my time running the account, i've managed to do what i think is a lot:
                    <br />
                    • grew a natural following of 4k+ <br />
                    • made a discord server with 100+ active members<br />
                    • hosted parking lot events during covid to get people outside<br />
                    • ran an annual holiday drive which collected 5k+ in donations<br />
                    <br /><br />
                    honestly, i'm most proud of the relationships i've built in this space. this was my intro to 
                    business and community building. believe it or not, tons of sneaker resellers have went down my 
                    path, and i'm not only finding my old friends in tech but making new ones. 
                  </h1>
              </div>
              <div className="border-l-2 border-black dark:border-white h-full ml-2"></div>
            </div>
            <div className="flex flex-row h-auto w-full gap-2">
              <div className="flex flex-col gap-2">
                <h1 className="text-text2 font-bold dark:text-white mt-4" 
                  style={{fontFamily: "'Roboto Mono', monospace"}}>
                  soma: 
                </h1>
                <h1 className="text-textsm dark:text-white mb-4" 
                  style={{fontFamily: "'Roboto Mono', monospace"}}>
                    <strong>accept who you are, and become more.</strong>
                    <br /><br />
                    i joined soma this summer hot off a successful semester with eir. but i knew damn well that 
                    people in this space were different. this wasn't a student vc game anymore. people actually
                    knew their shit. i had to learn. 
                    <br /><br />
                    i spent the first couple weeks carving a niche, landing on ai safety. i could not fathom 
                    getting technical enough to the point where i could have sophisticated discussion. but, i knew people were 
                    interested in this space, but everyone was still, at this point, doing stuff on their own. 
                    we had a reads channel where people would send articles they found interesting. i proposed 
                    an idea to make a newsletter out of these reads, to get discussions, and bring thoughts together. 
                    <br /><br />
                    it started off as a python script that turned slack channel .txt files into pretty print, and it turned 
                    into a full blown team of people who cared about doing the same. all it took was that first step. 
                    <br /><br />
                    it then led me to do less technical things - after i was selected to join the ai deal group, i noticed 
                    that none of us actually knew each other. our conversations were always groupthink, cuz we were scared to 
                    disagree. so i proposed that we should do a couple team building events. productivity went through the roof - 
                    obviously, community-driven growth is not bs. which also led to me learning a lot more about what i was interested in from a technical standpoint.
                    <br /><br />
                    i've got a lot more to learn, but that means a lot more to do. 
                  </h1>
              </div>
              <div className="border-l-2 border-black dark:border-white h-full ml-2"></div>
            </div>
          </div>
          <div className="border-b-2 border-black dark:border-white w-full"></div>
        </div>
      </div>
    </div>
  );
};

export default Transition(Community);
