import React from 'react';
import { motion } from "framer-motion";

const Transition = (OgComponent) => {
    return function WrappedComponent(props) {
        return ( 
            <>
                <OgComponent {...props} />
                <motion.div 
                    className="slide-in"
                    initial={{scaleY: 0}}
                    animate={{scaleY: 0}}
                    exit={{scaleY: 1}}
                    transition={{duration: 1, ease: [0.22, 0.1, 0.36, 1.0]}}
                    zIndex={1000}
                /> 
                <motion.div
                    className="slide-out"
                    initial={{scaleY: 1}}
                    animate={{scaleY: 0}}
                    exit={{scaleY: 0}}
                    transition={{duration: 1, ease: [0.22, 0.1, 0.36, 1.0]}}
                    zIndex={1000}
                />
            </>   
        );
    }
}

export default Transition;