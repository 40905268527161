import React from 'react';


const Instagram = () => {
    const handleClick = () => {
        window.open('https://www.instagram.com/nathanlee.jpg', '_blank');
    };

    return (
        <button
            onClick={handleClick}
            className="w-12 h-12 bg-contain bg-no-repeat bg-center hover:scale-110 transition-opacity dark:invert"
            style={{ backgroundImage: "url('/instagram.svg')" }}
            aria-label="instagram"
        />
    );
};

export default Instagram;
