import React from 'react';


const Twitter = () => {
    const handleClick = () => {
        window.open('https://twitter.com/juicy_kix', '_blank');
    };

    return (
        <button
            onClick={handleClick}
            className="w-12 h-12 bg-contain bg-no-repeat bg-center hover:scale-110 transition-opacity dark:invert"
            style={{ backgroundImage: "url('/twitter.svg')" }}
            aria-label="Twitter"
        />
    );
};

export default Twitter;
