import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import Twitter from '../buttons/Twitter';
import Instagram from '../buttons/Instagram';
import Linkedin from '../buttons/Linkedin';
import Navbar from './Navbar';
import { useNavigate } from 'react-router-dom';
import Soma from '../buttons/soma';
import Cher from '../buttons/cher';
import Plugandplay from '../buttons/plugandplay';
import Zfellows from '../buttons/zfellows';
import Luma from '../buttons/luma';
import Eeg from '../buttons/eeg'; 
import Mail from '../buttons/Mail';
import Transition from '../transition';

const Homepage = () => {
    const [showContent, setShowContent] = useState(false);
    const contentRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setShowContent(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (showContent && contentRef.current) {
            const navbar = document.querySelector('.navbar'); // Add this line
            const navbarHeight = navbar ? navbar.offsetHeight : 0; // Add this line
            window.scrollTo({
                top: contentRef.current.offsetTop - navbarHeight - 20, // Adjust scroll position
                behavior: 'smooth'
            });
        }
    }, [showContent]);

    return (
        <div className="relative bg-light dark:bg-dark w-full min-h-screen snap-y snap-mandatory overflow-y-scroll">
            <Navbar className="sticky top-0 left-0 right-0 z-50 navbar" /> {/* Add 'navbar' class */}
            <div className="h-screen flex items-center justify-center snap-start">
                <div className="p-10 mt-20 flex flex-col md:flex-row justify-between items-center gap-10 w-full">
                    <div className="flex flex-col gap-2 mt-6 w-full xs:text-center md:text-left">
                        <TypeAnimation 
                            sequence={[
                            "hi, i'm nathan."
                            ]}
                            wrapper="span"
                            className="font-bold text-textmain inline-block w-full xs:text-center md:text-left dark:text-white"
                            style={{
                                fontFamily: "'Roboto Mono', monospace",
                            }}
                        />
                        <p className="text-textsm dark:text-white" style={{fontFamily: "'Roboto Mono', monospace"}}>
                            serving founders @nyu. yes, i still type in lowercase.
                        </p>
                    </div>
                    <div className="flex flex-row gap-10 items-center justify-center md:justify-end mt-6 w-full">
                        <Mail />
                        <Twitter />
                        <Instagram />
                        <Linkedin />
                    </div>
                </div>
            </div>
            <div ref={contentRef} className={`transition-opacity duration-500 ${showContent ? 'opacity-100' : 'opacity-0'} pt-32`}> {/* Add pt-20 for padding-top */}
                <div className="min-h-screen flex flex-col gap-10 p-10 justify-start snap-start w-full">
                    <div className="flex flex-col gap-2">
                        <h1 className="text-textheading font-bold dark:text-white text-center md:text-left scale-90 xs:scale-100" 
                            style={{fontFamily: "'Roboto Mono', monospace"}}>
                            cool people i've worked for: 
                        </h1>
                        <div className="border-b-2 border-black dark:border-white w-full mt-2"></div>
                    </div>
                    <div className="flex flex-col gap-5 justify-start xs:items-center sm:items-start">
                        {[
                            { title: "soma capital - scout, ai (current)", Component: Soma },
                            { title: "nyu eeg - eir director/founder (current)", Component: Eeg },
                            { title: "zfellows - projects (current)", Component: Zfellows },
                            { title: "lu.ma - growth + product (spring '24)", Component: Luma },
                            { title: "plug & play - venture fellow (fall '23)", Component: Plugandplay },
                            { title: "cher - bizdev (summer '23)", Component: Cher }
                        ].map(({ title, Component }) => (
                            <div key={title} className="flex flex-col xs:flex-row justify-between items-center w-full sm:scale-100 xs:scale-70">
                                <div className="flex flex-col gap-2 w-full xs:w-auto xs:-ml-8 sm:mx-0">
                                    <h1 className="text-textsm dark:text-white text-center xs:text-left" style={{fontFamily: "'Roboto Mono', monospace"}}>
                                        {title}
                                    </h1>
                                    <div className="border-b-2 border-black dark:border-white w-full xs:w-[380px] mt-2"></div>
                                </div>
                                <div className="scale-75 xs:scale-100 mt-2 xs:mt-0 flex-shrink-0">
                                    <Component />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Transition(Homepage);
