import React from 'react';


const Mail = () => {
    const handleClick = () => {
        window.location.href = 'mailto:nathan@nathanlee.xyz';
    };

    return (
        <button
            onClick={handleClick}
            className="w-12 h-12 bg-contain bg-no-repeat bg-center hover:scale-110 transition-opacity dark:invert"
            style={{ backgroundImage: "url('/Mail.svg')" }}
            aria-label="mail"
        />
    );
};

export default Mail;